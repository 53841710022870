import React, { useContext, useEffect } from 'react';
import { Layout, Menu, Dropdown, Avatar, Typography } from 'antd';
import {
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Ticket from 'components/Ticket';
import {
  localizationConstants,
  flagConstants,
  appConstants,
  resourceConstants,
  actionConstants,
} from 'constants/index';
import { localizationHelpers, userHelpers, commonHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { IRegionItem } from 'interfaces';
import { StoreContext } from 'contexts';
import PlatformGlobalSwitch from 'components/Platform/PlatformGlobalSwitch';
import SellerGlobalSwitch from 'components/Seller/SellerGlobalSwitch';
import { commonHooks } from 'hooks';
import SiteGlobalSwitch from 'components/Site/SiteGlobalSwitch';
import UpgradeCountdown from 'components/Upgrade';
import { useHistory } from 'react-router-dom';
import { NetworkContext } from 'contexts/network';
import configHelpers from 'helpers/config';

const { useCurrentRoute } = commonHooks;
const {
  getCommonConfig,
  getClientConfig,
  getUpgradeTimeRangeConfig,
} = configHelpers;
const { getCurrentLanguage, changeLanguage } = localizationHelpers;
const { logout, checkPermission } = userHelpers;
const { getUpgradeTimeRange } = commonHelpers;

const { baseErpUrl } = getCommonConfig();
const upgradeTimeRange = getUpgradeTimeRangeConfig();

const { Header } = Layout;

const { REGIONS } = localizationConstants;
const { STAFF_BFF } = appConstants;
const { UPGRADING_PROCESS } = resourceConstants;
const { BYPASS } = actionConstants;
const { FLAG_KEYS } = flagConstants;

const AppHeader: React.FC<{ onClickSiderIcon: () => void }> = ({
  onClickSiderIcon,
}) => {
  const { isOnlineMode } = useContext(NetworkContext);
  const { currentUser, featureFlagsData } = useContext(StoreContext);
  const { currentRoute } = useCurrentRoute();
  const history = useHistory();

  useEffect(() => {
    const handleNetworkChange = (isOnline: boolean) => {
      if (!getClientConfig()?.swRegistered) {
        return;
      }
    };

    const handleOnline = () => {
      handleNetworkChange(true);
    };

    const handleOffline = () => {
      handleNetworkChange(false);
    };

    window.addEventListener('online', handleOnline);

    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Check if user has permission to bypass upgrade process
  const hasPermissionBypassUpgradeProcess = checkPermission(
    currentUser.permissions,
    STAFF_BFF,
    UPGRADING_PROCESS,
    BYPASS
  );

  const { upgradeStartTime, upgradeEndTime, serviceName } =
    getUpgradeTimeRange(upgradeTimeRange, currentRoute) || {};

  // Check if the current time is in the upgrade time range, then navigate to the upgrade page
  useEffect(() => {
    // if user has permission to bypass upgrade process, then do nothing
    if (hasPermissionBypassUpgradeProcess) return;

    if (upgradeStartTime && upgradeEndTime) {
      const currentTime = new Date().getTime();
      if (
        currentTime >= upgradeStartTime.getTime() &&
        currentTime <= upgradeEndTime.getTime()
      ) {
        const upgradingParams = new URLSearchParams({
          upgradeStartTime: upgradeStartTime.getTime().toString(),
          upgradeEndTime: upgradeEndTime.getTime().toString(),
          serviceName: serviceName || '',
        }).toString();
        history.push({ pathname: '/upgrading', search: upgradingParams });
      }
    }
  }, [upgradeStartTime, upgradeEndTime, history]);

  // Set hidePlatformSwitch to true if the feature flag is enabled
  if (
    featureFlagsData[FLAG_KEYS.SPLIT_ORDER_BFF]?.enabled &&
    currentRoute?.hidePlatformSwitchByEpic
  ) {
    currentRoute.hidePlatformSwitch = true;
  }

  // Set showSiteSwitch to false if the feature flag for hideSiteSwitchWhenEnabledFlagKey is enabled
  if (
    !!currentRoute?.hideSiteSwitchWhenEnabledFlagKey &&
    featureFlagsData[currentRoute.hideSiteSwitchWhenEnabledFlagKey]?.enabled
  ) {
    currentRoute.showSiteSwitch = false;
  }

  const localizationMenu = (
    <Menu>
      {Object.values(REGIONS).map((el: IRegionItem) => (
        <Menu.Item key={el.key} onClick={() => changeLanguage(el.key)}>
          <Avatar src={el.flag} shape="square" />
          <span style={{ marginLeft: 10 }}>{el.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item data-testid="btn-logout" onClick={logout}>
        <LogoutOutlined />
        <span>{t('Logout')}</span>
      </Menu.Item>
    </Menu>
  );

  const currentRegion = REGIONS[getCurrentLanguage()];

  const surveyLinkTrackingParams = {
    contentName: `${currentRoute?.survey?.surveyText}Button`,
    regionName: 'AppHeader',
  };
  const surveyLinkChildApp = baseErpUrl ?? '*';
  const isShowSurveyLink =
    featureFlagsData[FLAG_KEYS.SHOW_SURVEY_POPUP_OMPRODUCT_1714]?.enabled;

  useEffect(() => {
    const receiveMessageFromIframe = (event: MessageEvent) => {
      if (
        // https://confluence.teko.vn/display/HCM9/OMPRODUCT-545%3A+Tracking+component+GiveFeedback
        event.data === 'component-rendered' &&
        !!currentRoute?.survey?.surveyText
      ) {
        const iframe = document.querySelector('iframe');
        iframe?.contentWindow?.postMessage(
          { eventName: 'visibleContent', data: surveyLinkTrackingParams },
          surveyLinkChildApp
        );
      }
    };
    window.addEventListener('message', receiveMessageFromIframe);

    return () => {
      window.addEventListener('message', receiveMessageFromIframe);
    };

    // eslint-disable-next-line
  }, [currentRoute]);

  const onClickSurveyLink = () => {
    const iframe = document.querySelector('iframe');
    iframe?.contentWindow?.postMessage(
      { eventName: 'click', data: surveyLinkTrackingParams },
      surveyLinkChildApp
    );
    window.open(currentRoute?.survey?.surveyLink, '_blank');
  };

  return (
    <Header className="app-header">
      <div className="d-flex align-items-center">
        <MenuOutlined
          data-testid="sider-icon"
          className="app-icon"
          onClick={onClickSiderIcon}
        />
      </div>

      <div className="d-flex align-items-center">
        <UpgradeCountdown
          upgradeStartTime={upgradeStartTime}
          upgradeEndTime={upgradeEndTime}
        />

        {isOnlineMode && <Ticket />}

        {isShowSurveyLink && !!currentRoute?.survey?.surveyLink && (
          <Typography.Link className="ml-base" onClick={onClickSurveyLink}>
            {t(`${currentRoute.survey.surveyText}`)}
          </Typography.Link>
        )}

        {currentRoute?.showSiteSwitch && (
          <SiteGlobalSwitch pathName={currentRoute.path} />
        )}

        {isOnlineMode && !currentRoute?.hidePlatformSwitch && (
          <PlatformGlobalSwitch />
        )}

        {isOnlineMode && !currentRoute?.hideSellerSwitch && (
          <SellerGlobalSwitch />
        )}

        {isOnlineMode && (
          <Dropdown overlay={localizationMenu} trigger={['click']}>
            <span className="app-user">
              <Avatar
                src={currentRegion && currentRegion.flag}
                shape="square"
              />
              <span className="label">
                {currentRegion && currentRegion.name}
              </span>
              <DownOutlined />
            </span>
          </Dropdown>
        )}

        {!getClientConfig().swRegistered || isOnlineMode ? (
          <Dropdown overlay={userMenu} trigger={['click']}>
            <span className="app-user">
              {currentUser.picture ? (
                <Avatar src={currentUser.picture} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
              <span className="label">
                {currentUser.name ||
                  currentUser.email ||
                  currentUser.phone_number}
              </span>
              <DownOutlined />
            </span>
          </Dropdown>
        ) : (
          <span className="app-user">
            {currentUser.picture ? (
              <Avatar src={currentUser.picture} />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
            <span className="label">
              {currentUser.name ||
                currentUser.email ||
                currentUser.phone_number}
            </span>
          </span>
        )}
      </div>
    </Header>
  );
};

export default AppHeader;
