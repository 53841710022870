// NOTE: ALL HELPER FUNCTION SHOULD BE INDEPENDENT TO PREVENT CIRCULAR DEPENDENCIES
export const parseJsonObject = (
  jsonString: string | undefined | null
): Record<string, any> => {
  if (!jsonString) {
    return {};
  }

  try {
    const parsed = JSON.parse(jsonString);

    // Check if the parsed value is an object and not null (typeof null is "object")
    if (parsed && typeof parsed === 'object' && !Array.isArray(parsed)) {
      return parsed as Record<string, unknown>;
    } else {
      return {};
    }
  } catch (e) {
    // If JSON.parse throws an error, the input was not valid JSON
    return {};
  }
};
