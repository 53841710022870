import { CalculatorOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  guideConstants,
  resourceConstants,
  roleConstants,
} from 'constants/index';
import { ACCOUNTING_PATHS, PURCHASING_PATHS } from 'constants/paths';
import { commonHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { lazy } from 'react';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseFmsUrl, baseErpUrl, baseSupplyChainUrl } = getCommonConfig();

const { FMS, STAFF_BFF } = appConstants;
const {
  TOOLS_IMPORT,
  BANKING,
  SYSTEM_ADMIN,
  CREDIT_APPROVAL_CONFIGS,
  PURCHASE_INVOICES,
  RECEIVING_TICKET_SUPPLIER,
  ACCOUNTING,
} = resourceConstants;
const { READ, CREATE, READ_POR_INVOICE, UPDATE } = actionConstants;
const { ACCOUNTANT, ACCOUNTANT_READONLY } = roleConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const accountingRoutes = [
  // Parent menu
  {
    path: '/accounting',
    name: t('Accounting.Root'),
    icon: CalculatorOutlined,
    children: [
      '/accounting/transaction-importer',
      '/accounting/bank-accounts',
      '/accounting/bank-statements',
      '/accounting/pending-bank-statements',
      '/accounting/close-the-books',
      '/accounting/customer-credit-limits',
      '/accounting/refund-requests',
      '/accounting/receiving-ticket-supplier',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/accounting/transaction-importer',
    name: t('Accounting.TransactionImport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, ACCOUNTING_PATHS.TransactionImport),
    permissions: [permission(FMS, TOOLS_IMPORT, READ)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.ACC_IMPORT_TRANSACTION,
    },
  },
  {
    exact: true,
    path: '/accounting/bank-accounts',
    name: t('Accounting.BankAccounts'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, ACCOUNTING_PATHS.BankAccounts),
    permissions: [permission(FMS, BANKING, READ)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.ACC_BANK_ACCOUNT,
    },
  },
  {
    exact: true,
    path: '/accounting/bank-statements',
    name: t('Accounting.BankStatements'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, ACCOUNTING_PATHS.BankStatements),
    permissions: [permission(FMS, BANKING, READ)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.ACC_BANK_STATEMENTS,
    },
  },
  {
    exact: true,
    path: '/accounting/pending-bank-statements',
    name: t('Accounting.PendingBankStatements'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, ACCOUNTING_PATHS.PendingBankStatements),
    permissions: [permission(FMS, BANKING, READ)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.ACC_PENDING_BANK_STATEMENTS,
    },
  },
  {
    exact: true,
    path: '/accounting/close-the-books',
    name: t('Accounting.CloseTheBooks'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, ACCOUNTING_PATHS.CloseTheBooks),
    permissions: [permission(FMS, SYSTEM_ADMIN, READ)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.ACC_CLOSE_THE_BOOKS,
    },
  },
  {
    exact: true,
    path: '/accounting/customer-credit-limits',
    name: t('Accounting.CustomerCreditLimits'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ACCOUNTING_PATHS.CustomerCreditLimits),
    permissions: [permission(STAFF_BFF, CREDIT_APPROVAL_CONFIGS, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ACCOUNTING.ACCOUNTING,
      userGuideCode: USER_GUIDE_CODE.ACCOUNTING.CUSTOMER_CREDIT_LIMITS,
    },
  },
  {
    exact: true,
    path: '/accounting/refund-requests',
    name: t('Accounting.RefundRequests'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ACCOUNTING_PATHS.RefundRequests),
    permissions: [
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ACCOUNTING.ACCOUNTING_CASHIER,
      userGuideCode: USER_GUIDE_CODE.ACCOUNTING.REFUND_REQUESTS,
    },
  },
  {
    exact: true,
    path: '/accounting/purchase-invoices/create',
    name: t('Accounting.PurchaseInvoiceCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ACCOUNTING_PATHS.PurchaseInvoiceCreate),
    permissions: [permission(STAFF_BFF, PURCHASE_INVOICES, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CS.CS,
      userGuideCode: USER_GUIDE_CODE.CS.PURCHASEINVOICE_CREATE,
    },
  },
  {
    exact: true,
    path: '/accounting/purchase-invoices/confirm',
    name: t('Accounting.PurchaseInvoiceConfirm'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ACCOUNTING_PATHS.PurchaseInvoiceConfirm),
    permissions: [permission(STAFF_BFF, PURCHASE_INVOICES, UPDATE)],
  },
  {
    exact: true,
    path: '/accounting/purchase-return-invoices/create',
    name: t('Accounting.PurchaseReturnInvoiceCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ACCOUNTING_PATHS.PurchaseReturnInvoiceCreate),
    permissions: [permission(STAFF_BFF, ACCOUNTING, READ_POR_INVOICE)],
  },
  {
    exact: true,
    path: '/accounting/receiving-ticket-supplier',
    name: t('Accounting.ReceivingTicketSupplier'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.ReceivingTicketSupplier
    ),
    permissions: [permission(STAFF_BFF, RECEIVING_TICKET_SUPPLIER, READ)],
  },
];

export default accountingRoutes;
