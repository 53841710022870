import { FireOutlined } from '@ant-design/icons';
import { INTERNAL_PATH } from 'constants/paths';
import { commonHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { lazy } from 'react';
import {
  appConstants,
  resourceConstants,
  actionConstants,
} from 'constants/index';

const { permission } = commonHelpers;
const { STAFF_BFF } = appConstants;
const { WEBSITE_CONFIG } = resourceConstants;
const { UPDATE } = actionConstants;

const MenuList = lazy(() => import('containers/MenuList'));
const FlagsList = lazy(() => import('containers/FLagsList'));
const ConfigUpgradeTime = lazy(() => import('containers/ConfigUpgradeTime'));

const internalRoutes = [
  {
    name: t('Internal.Root'),
    path: INTERNAL_PATH.ROOT,
    icon: FireOutlined,
    children: [
      INTERNAL_PATH.MENU_LIST_PATH,
      INTERNAL_PATH.FLAGS_LIST_PATH,
      INTERNAL_PATH.CONFIG_UPGRADE_TIME,
    ],
  },
  {
    exact: true,
    path: INTERNAL_PATH.MENU_LIST_PATH,
    name: t('Router.Root'),
    component: MenuList,
  },
  {
    path: INTERNAL_PATH.FLAGS_LIST_PATH,
    name: t('Internal.FlagsList.Root'),
    hideTitle: true,
    component: FlagsList,
  },
  {
    path: INTERNAL_PATH.CONFIG_UPGRADE_TIME,
    name: t('Internal.ConfigUpgradeTime.Root'),
    component: ConfigUpgradeTime,
    permissions: [permission(STAFF_BFF, WEBSITE_CONFIG, UPDATE)],
  },
];

export default internalRoutes;
