import _ from 'lodash';
import baseFavicon from 'assets/images/brands/teko/teko_favicon.png';
import baseLogo from 'assets/images/brands/teko/teko_logo.png';
import { appBrandConstants } from 'constants/index';
import userHelpers from 'helpers/user';
import { IPermission, IRoute, UpgradeTimeRange } from 'interfaces';
import userServices from 'services/apis/user';
import configHelpers from 'helpers/config';
import { parseJsonObject } from './independent';

const { getClientConfig } = configHelpers;
const { appName: clientAppName, appLogo, appFavicon } = getClientConfig();
const { BRAND_CONFIGS } = appBrandConstants;
const { getCurrentSeller } = userHelpers;
const { getUserInfo } = userServices;

/**
 * Builds a permission object from 2 or 3 arguments
 * @param app
 * @param resource
 * @param action optional
 */
export const permission = (
  app: IPermission['app'],
  resource: IPermission['resource'],
  action?: IPermission['action']
): IPermission => ({ app, resource, action });

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const getBrandConfig = () => {
  const appName =
    clientAppName ||
    _.get(BRAND_CONFIGS, [window.location.host, 'appName'], 'Staff Desktop');
  const logo =
    appLogo || _.get(BRAND_CONFIGS, [window.location.host, 'logo'], baseLogo);
  const favicon =
    appFavicon ||
    _.get(BRAND_CONFIGS, [window.location.host, 'favicon'], baseFavicon);
  return { appName, logo, favicon };
};

const getExternalPath = (route: IRoute) => {
  let routePath = route.path;
  if (route.needAppendSellerId) {
    routePath += '&seller_id=' + getCurrentSeller();
  }
  if (route.needAppendUserId) {
    const { sub } = getUserInfo();
    routePath += '&user_id=' + sub;
  }
  return routePath;
};

const getUpgradeTimeRange = (
  upgradeTimeRange?: UpgradeTimeRange,
  currentRoute?: IRoute
) => {
  if (!upgradeTimeRange || !currentRoute) {
    return undefined;
  }

  // Check if any key of upgradeTimeRange is matched with current host (math by startWith)
  const matchedUpgradeTimeRangeKey = Object.keys(upgradeTimeRange).find(key =>
    currentRoute.iFrameSrc?.startsWith(key)
  );

  if (!matchedUpgradeTimeRangeKey) {
    return undefined;
  }

  return {
    upgradeStartTime: new Date(
      upgradeTimeRange[matchedUpgradeTimeRangeKey].from
    ),
    upgradeEndTime: new Date(upgradeTimeRange[matchedUpgradeTimeRangeKey].to),
    serviceName: upgradeTimeRange[matchedUpgradeTimeRangeKey]?.service,
  };
};

export default {
  permission,
  getWindowDimensions,
  getBrandConfig,
  getExternalPath,
  getUpgradeTimeRange,
  parseJsonObject,
};
