export default {
  CREATE: 'create',
  CREATE_WEB: 'create:web',
  CREATE_UPSERT: 'create:upsert',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  READ_UI: 'read:ui',
  TRACKING: 'read:tracking',
  READ_MAPPING_MASTER_CAT: 'read:mappingmastercat',
  READ_MULTI_SELLERS: 'read:multi_sellers',
  CREATE_MULTI_SELLERS: 'create:multi_sellers',
  READ_SITE_AND_WAREHOUSE: 'read:site_and_warehouse',
  READ_SKU_REQUEST: 'read:sku_request',
  READ_IMBALANCED_STOCKS: 'read:imbalanced_stocks',
  UPDATE_STOCK_REQUEST: 'update:approve',
  VIEW_SALES_REPORT: 'read:view_sales_report',
  QUICK_CREATE: 'update:quick_create',
  VIEW_AUDIT_LOG_SC: 'read:supplychain',
  CREATE_IMPORT: 'create:import',
  READ_REVENUE_REPORT: 'read:revenue_report',
  CREATE_REPORT: 'create:report',
  CREATE_REPORT_V2: 'create:report_v2',
  CREATE_VNPOST: 'create:vnpost',
  UPDATE_IMPORT: 'update:import',
  READ_SESSION_WEB: 'read:session:web',
  CREATE_SESSION_WEB: 'create:session:web',
  READ_BOM: 'read:bom',
  CREATE_BOM: 'create:bom',
  READ_ALL: 'read:all',
  READ_PO: 'read:po',
  READ_POR: 'read:por',
  VIEW_AUDIT_LOG_LO: 'read:inhouse',
  READ_CLEARANCE_REQUEST: 'read:clearance_request',
  READ_WH: 'read:warehouse',
  READ_SOR: 'read:sor',
  READ_SO: 'read:so',
  READ_DELIVERY_REPORT: 'read:delivery_report',
  READ_OTHER_RECEIPT: 'read:other_receipt',
  READ_OTHER_ISSUE: 'read:other_issue',
  READ_DISCOUNT_APPROVED_REPORT: 'read:discount_approved_report',
  READ_REPORT_CASH_FUNDS: 'read:report-cash-funds',
  READ_SITE_TRANSFER: 'read:site_transfer',
  READ_PROGRESS: 'read:progress',
  BYPASS: 'read:bypass',
  UPDATE_INBOUND: 'update:inbound',
  UPDATE_NORMAL: 'update:normal',
  UPDATE_WEB: 'update:web',
  READ_POR_INVOICE: 'read:por_invoice',
  RECORD_WEB: 'update:record_web',
  READ_WEB: 'read:web',
  UPDATE_SERIAL_WEB: 'update:serial_web',
  READ_SERIAL_CPN: 'read:serial_cpn',
  READ_CHART_BY_TIME: 'read:chart_by_time',
  READ_CHART_BY_SITES: 'read:chart_by_sites',
  READ_DASHBOARD: 'read:dashboard',
};
