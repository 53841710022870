import { IClientConfig, ICommonConfig, UpgradeTimeRange } from 'interfaces';
import { parseJsonObject } from './independent';

const getConfig = (configKey: 'commonConfig' | 'clientConfig') => {
  if (window[configKey]) {
    localStorage.setItem(configKey, JSON.stringify(window[configKey]));
    return window[configKey];
  } else {
    const config = localStorage.getItem(configKey);
    if (config) {
      return JSON.parse(config);
    }
  }
};

const getCommonConfig = () => getConfig('commonConfig') as ICommonConfig;
const getClientConfig = () => getConfig('clientConfig') as IClientConfig;
const getUpgradeTimeRangeConfig = (): UpgradeTimeRange => {
  const { upgradeTimeRange: upgradeTimeRangeInStr } = getClientConfig();
  const upgradeTimeRange = parseJsonObject(upgradeTimeRangeInStr || '');
  return upgradeTimeRange;
};

export default {
  getCommonConfig,
  getClientConfig,
  getUpgradeTimeRangeConfig,
};
